import React from "react";

const NotFound: React.FC = () => {

  return (
      <div>
        <p>404 error - page not found</p>
      </div>
  )
}

export default NotFound;